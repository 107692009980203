import React, { Component } from "react";
import { Table, Space, Button, Tooltip } from "antd";
import * as _ from "underscore";
import moment from "moment";
import "../main.css";
import { Helpers } from "../helpers/helpers";
import { RenderHelpers } from "../helpers/renderHelpers";
import { AppContext } from "../dataContext";
import { EditOutlined, FileFilled, LinkOutlined, ReloadOutlined  } from "@ant-design/icons";
import DisableWrapper from "../components/disableWrapper";

const fullDateFormat = "yyyy-MM-DD HH:mm:ss";

class ProviderInstrumentTable extends Component {
  static contextType = AppContext;

  constructor(props) {
    super();

    this.id = props.params.id;
    this.props = props;
    this.state = {
      loading: false,
      providerInstruments: [],
      providers: this.props.providers,
    };
  }

  componentDidMount = async () => {};

  getProviderInstrumentColumns = (hasWriteRole) => {
    return [
      {
        title: "Provider",
        dataIndex: "providerId",
        width: 100,
        render: (text, record) => {
          let foundProvider = this.props.providers.find(
            (x) => x.id === record.providerId
          );
          return foundProvider ? foundProvider.name : "Unknown";
        },
      },
      {
        title: "External Instrument Name",
        dataIndex: "name",
        width: 150,
        render: RenderHelpers.renderEnabled,
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Bloomberg ID",
        dataIndex: "bloombergId",
        width: 150,
      },
      {
        title: "ISIN",
        dataIndex: "isin",
        width: 150,
      },
      {
        title: "Timestamp",
        dataIndex: "timestamp",
        render: (text) =>
          text ? moment(text).format(fullDateFormat).toString() : "",
      },
      {
        width: 50,
        render: (text, record) => {
          const editButton = (
            <DisableWrapper enabledMessage ="Edit provider instrument" enabledColor="blue">
              <Button
                type="primary"
                shape="circle"
                onClick={() => this.context.showProviderInstrumentEdit(record)}
                icon={<EditOutlined />}
              />
            </DisableWrapper>
          );

          const linkButton = record.used ? (
            <a
              href={
                "#/instruments/" +
                encodeURIComponent(record.name) +
                "/providers/" +
                record.providerName
              }
            >
              <Tooltip title="Navigate to connected instruments" color="blue">
                <Button type="primary" shape="circle" icon={<LinkOutlined />} />
              </Tooltip>
            </a>
          ) : (
            <Button
              disabled
              type="primary"
              shape="circle"
              icon={<LinkOutlined />}
            />
          );

          const resubscribeButton = record.used ? (
            <DisableWrapper enabledMessage ="Attempt to resubscribe to the LP." enabledColor="blue">
              <Button
                type="primary"
                shape="circle"
                onClick={() => this.context.showProviderInstrumentResubscribe(record)}
                icon={<ReloadOutlined  />}
              />
            </DisableWrapper>
          ) : (
            <Button
              disabled
              type="primary"
              shape="circle"
              icon={<ReloadOutlined />}
            />
          );

          return (
            <Space>
              {editButton}
              {linkButton}
              {resubscribeButton}
            </Space>
          );
        },
      },
    ];
  };

  render() {
    return (
      <Table
        rowKey="id"
        size="middle"
        onChange={this.props.onChange}
        loading={this.props.loading}
        columns={this.getProviderInstrumentColumns(this.context.hasWriteRole)}
        dataSource={this.props.providerInstruments}
        pagination={{
          size: 10,
          current: this.props.currentPage,
          total: this.props.total,
          showSizeChanger: false,
        }}
        locale={{
          emptyText:
            "No results available. Please modify your search criteria.",
        }}
      />
    );
  }
}

ProviderInstrumentTable.contextType = AppContext;
export default Helpers.withParams(ProviderInstrumentTable);

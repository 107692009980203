import React, { Component } from "react";
import { AppContext } from "../../../dataContext";
import PricingHubProxy from "../../../proxies/pricinghubProxy";
import { Form, Select,  Spin } from "antd";

class TagSettings extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.pricingHubProxy = new PricingHubProxy();

    console.log(props)
    this.state = {
      loading: true,
      tagOptions: [], 
      selectedTags: [], 
      disabledTags: []
    };
  }

  setInitialValues(connectorMap) {
    console.log("Setting inital vales: ", connectorMap);
    const manualTags = connectorMap.tags.filter(tag => !tag.isAuto).map(tag => tag.name.trim());
    const autoTags = connectorMap.tags
    .filter(tag => tag.isAuto)
    .map(tag => tag.name.trim());
    connectorMap.tags = [...manualTags, ...autoTags]; // need just the names set
    this.setState({
      selectedTags: manualTags,
      disabledTags: autoTags
    });
  }

  componentDidMount = async () => {
    try {
      const tags = await this.pricingHubProxy.getTagOptions(null, true);
      console.log("got tags", tags)
      this.setState({ tagOptions: tags, loading: false });
    } catch (error) {
      console.error("Failed to fetch manual tags:", error);
      this.setState({ loading: false });
    }
  };

  handleTagChange = (values) => {
    this.setState({ selectedTags: values });
  };

  handleTagBlur = () => {
    const { selectedTags, tagOptions } = this.state;

    const newTags = selectedTags.filter(tag => !tagOptions.includes(tag));

    if (newTags.length > 0) 
      this.pricingHubProxy.updateManualTags(newTags).catch((error) => {
        console.error("Failed to update manual tags:", error);
      });

      this.setState({ tagOptions: [...tagOptions, ...newTags] });
    }

  render() {
    const { loading, tagOptions, selectedTags, disabledTags } = this.state;

    return (
      <Form.Item name="tags" label="Market Tags">
        {loading ? (
          <Spin />
        ) : (
          <Select
            mode="tags"
            placeholder="Add or create manual tags"
            value= {["Test"]}
            onChange={this.handleTagChange}
            onBlur={this.handleTagBlur}
          >
            {tagOptions.map((tag) => (
              <Select.Option key={tag.id} value={tag.name}>
                {tag.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  }
}

export default TagSettings;

export class InstrumentPageDataHelper {
  static convertResponseToInstrumentPageObject(instrumentWithFeeds) {
    var primaryPI = instrumentWithFeeds.primaryFeed.providerInstrument
    var secondaryPI = instrumentWithFeeds.secondaryFeed?.providerInstrument;

    var primaryConnectorMap = instrumentWithFeeds.primaryFeed.connectorMap
    var secondaryConnectorMap = instrumentWithFeeds.secondaryFeed?.connectorMap;

    var converted = {
      id: instrumentWithFeeds.instrument.id,
      enabled: instrumentWithFeeds.instrument.enabled,
      publishedName: instrumentWithFeeds.instrument.publishedName,
      publishedSource: instrumentWithFeeds.instrument.publishedSource,
      description: instrumentWithFeeds.instrument.description,
      mt4: instrumentWithFeeds.instrument.mt4Name ? true : false,
      mt4Name: instrumentWithFeeds.instrument.mt4Name,
      timeoutFailover: instrumentWithFeeds.instrument.instrumentTimeoutFailover,
      timeoutInSec: instrumentWithFeeds.instrument.instrumentTimeoutInSec,
      timeoutInSecAtNight: instrumentWithFeeds.instrument.instrumentTimeoutInSecAtNight,
      backwardationFix: instrumentWithFeeds.instrument.backwardationFix,
      zeroPriceFilter: instrumentWithFeeds.instrument.zeroPriceFilter,
      maxChangeMovementValue: instrumentWithFeeds.instrument.maxChangeMovementValue,
      maxChangeNumberOfPrices: instrumentWithFeeds.instrument.maxChangeNumberOfPrices,
      maxChangeDiscard: instrumentWithFeeds.instrument.maxChangeDiscard,
      providerName: instrumentWithFeeds.primaryFeed.provider.name,
      tags: instrumentWithFeeds.tags,

      primaryConnectorMapId: primaryConnectorMap?.id,
      primaryId: primaryPI?.id,
      primaryName: primaryPI?.name,
      primaryFeed: instrumentWithFeeds.primaryFeed.provider.name,
      primaryEnabled: primaryPI?.enabled,
      primaryIsTest: !!primaryPI?.autoGenerateConfig,
      primaryActivityStatus: primaryConnectorMap?.activityStatus,
      primaryFairValue: primaryConnectorMap?.fairValue,
      primaryFeedConnectorInstance: instrumentWithFeeds.primaryFeed.provider.isAActive ? "A" : "B",

      secondaryConnectorMapId: secondaryConnectorMap?.id,
      secondaryId: secondaryPI?.id,
      secondaryName: secondaryPI?.name,
      secondaryFeed: instrumentWithFeeds.secondaryFeed?.provider.name,
      secondaryEnabled: secondaryPI?.enabled,
      secondaryIsTest: !!secondaryPI?.autoGenerateConfig,
      secondaryActivityStatus: secondaryConnectorMap?.activityStatus,
      secondaryFairValue: secondaryConnectorMap?.fairValue,
      secondaryFeedConnectorInstance: instrumentWithFeeds.secondaryFeed?.provider.isAActive ? "A" : "B",

      isPrimaryActive: primaryConnectorMap?.activityStatus === 2,
      isSecondaryActive: secondaryConnectorMap?.activityStatus === 2
    };

    console.debug("Converted instrument.", instrumentWithFeeds, converted);
    return converted;
  };

  static removePrimaryOrSecondary(connectorMap, deletedConnectorMapId) {
    var toBeRemoved = [];
    if (connectorMap.primaryConnectorMapId === deletedConnectorMapId) {
      var found = connectorMap.constituentPrices.filter(
        (x) =>
          x.name === connectorMap.primaryName &&
          x.feed === connectorMap.primaryFeed
      )[0];
      if (found) toBeRemoved.push(found);
      connectorMap.primaryConnectorMapId = undefined;
      connectorMap.primaryId = undefined;
      connectorMap.primaryName = undefined;
      connectorMap.primaryFeed = undefined;
      connectorMap.primaryEnabled = undefined;
      connectorMap.primaryIsTest = undefined;
      connectorMap.primaryStatus = undefined;
    } else if (connectorMap.secondaryConnectorMapId === deletedConnectorMapId) {
      found = connectorMap.constituentPrices.filter(
        (x) =>
          x.name === connectorMap.secondaryName &&
          x.feed === connectorMap.secondaryFeed
      )[0];
      if (found) toBeRemoved.push(found);
      connectorMap.secondaryConnectorMapId = undefined;
      connectorMap.secondaryId = undefined;
      connectorMap.secondaryName = undefined;
      connectorMap.secondaryFeed = undefined;
      connectorMap.secondaryEnabled = undefined;
      connectorMap.secondaryIsTest = undefined;
      connectorMap.secondaryStatus = undefined;
    }
    toBeRemoved.forEach((x) => {
      var index = connectorMap.constituentPrices.indexOf(x);
      connectorMap.constituentPrices.splice(index, 1);
    });
  }

  static removeSecondaryFeed(instrument) {
    instrument.secondaryConnectorMapId = undefined;
    instrument.secondaryId = undefined;
    instrument.secondaryName = undefined;
    instrument.secondaryFeed = undefined;
    instrument.secondaryEnabled = undefined;
    instrument.secondaryStatus = undefined;
    instrument.secondaryStatusDescription = undefined;
  }

  static merge(instrument, modifiedInstrument) {
    instrument.enabled = modifiedInstrument.enabled;
    instrument.publishedName = modifiedInstrument.publishedName;
    instrument.publishedSource = modifiedInstrument.publishedSource;
    instrument.description = modifiedInstrument.description;
    instrument.mt4Name = modifiedInstrument.mt4Name;
    instrument.timeoutFailover =
      modifiedInstrument.instrumentTimeoutFailover;
    instrument.timeoutInSec = modifiedInstrument.instrumentTimeoutInSec;
    instrument.timeoutInSecAtNight =
      modifiedInstrument.instrumentTimeoutInSecAtNight;
    instrument.backwardationFix = modifiedInstrument.backwardationFix;
    instrument.zeroPriceFilter = modifiedInstrument.zeroPriceFilter;
    instrument.maxChangeMovementValue =
      modifiedInstrument.maxChangeMovementValue;
    instrument.maxChangeNumberOfPrices =
      modifiedInstrument.maxChangeNumberOfPrices;
    instrument.maxChangeDiscard = modifiedInstrument.maxChangeDiscard;
    instrument.tags = modifiedInstrument.tags;
    instrument.dp = modifiedInstrument.dp;
  }

  static mergeExistingWithNewInstrument(existingInstrument, newInstrument) {
    existingInstrument.enabled = newInstrument.enabled;
    existingInstrument.publishedName = newInstrument.publishedName;
    existingInstrument.publishedSource = newInstrument.publishedSource;
    existingInstrument.description = newInstrument.description;
    existingInstrument.mt4Name = newInstrument.mt4Name;
    existingInstrument.timeoutFailover = newInstrument.instrumentTimeoutFailover;
    existingInstrument.timeoutInSec = newInstrument.instrumentTimeoutInSec;
    existingInstrument.timeoutInSecAtNight = newInstrument.instrumentTimeoutInSecAtNight;
    existingInstrument.backwardationFix = newInstrument.backwardationFix;
    existingInstrument.zeroPriceFilter = newInstrument.zeroPriceFilter;
    existingInstrument.maxChangeMovementValue = newInstrument.maxChangeMovementValue;
    existingInstrument.maxChangeNumberOfPrices = newInstrument.maxChangeNumberOfPrices;
    existingInstrument.maxChangeDiscard = newInstrument.maxChangeDiscard;
    existingInstrument.tags = newInstrument.tags;
  }

  static applyLatestPriceToInstruments(redisPrices, instruments) {
    if (redisPrices && instruments) {
      instruments.forEach((instrument) => {
        var prices = redisPrices.filter(price => price.internalId === instrument.publishedName)
        var activePrice = prices.filter(price => price.active)[0]
        var inactivePrice = prices.filter(price => price.active === false)[0] // todo: this will currently always be undefined. The connectors only store active price.
        if(activePrice) {
        instrument.timestamp = activePrice.time;
        instrument.arrow = instrument.bid < activePrice.bid ? "u" : activePrice.ask === activePrice.bid ? "e" : "d";
        instrument.bid = activePrice.bid;
        instrument.ask = activePrice.ask;
        if(instrument.constituentPrices) {
          var foundActive = instrument.constituentPrices.filter(x => x.name?.toLowerCase() === activePrice.externalId.toLowerCase() && x.feed?.toLowerCase() === activePrice.connectorName.toLowerCase())[0];
          if (foundActive) { 
            foundActive.bid = activePrice.bid;
            foundActive.ask = activePrice.ask;
            foundActive.timestamp = activePrice.time;
            foundActive.isActive = true
           }
           if(inactivePrice) {
            var foundInactive = instrument.constituentPrices.filter(x => x.name?.toLowerCase() === inactivePrice.externalId.toLowerCase() && x.feed?.toLowerCase() === inactivePrice.connectorName.toLowerCase())[0];
            if (foundInactive) { 
              foundInactive.bid = activePrice.bid;
              foundInactive.ask = activePrice.ask;
              foundInactive.timestamp = activePrice.time;
              foundInactive.isActive = true
             }
          }
        }

      }
      });
      }
    }

    static applyLatestPriceStatusToInstruments(priceStatuses, instruments) {
      if (priceStatuses && instruments) {
        instruments.forEach((instrument) => {
          // should get price statuses come in for both active and inactive
          // for each instrument, find primary status and secondary status. if exists
          var priceStatusesForInstrument = priceStatuses.filter(status => status.instrumentName === instrument.publishedName)
          var primaryStatus = priceStatusesForInstrument.filter(status => 
            status.providerInstrumentName === instrument.primaryName && 
            status.providerName.toLowerCase() === instrument.primaryFeed.toLowerCase())[0]
          var secondaryStatus = instrument.secondaryName ? priceStatusesForInstrument.filter(status => 
            status.providerInstrumentName === instrument.secondaryName && 
            status.providerName.toLowerCase()  === instrument.secondaryFeed.toLowerCase() )[0] : undefined // todo: this will currently always be undefined. The connectors only store active price.
        if(primaryStatus) {
        instrument.primaryStatus = primaryStatus.priceStatus;
        instrument.constituentPrices = [{
          feed : primaryStatus.providerName,
          name : primaryStatus.providerInstrumentName

        }]
        if(secondaryStatus) { 
          instrument.secondaryStatus = secondaryStatus.priceStatus;
          instrument.constituentPrices.push({
            feed : secondaryStatus.providerName,
            name : secondaryStatus.providerInstrumentName
          })
        }
      }
        });
        }
      }
  }

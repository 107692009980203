import React, { Component } from "react";
import { Input, Form, Select, Divider, Typography } from "antd";
import * as _ from "underscore";
import "../../../main.css";
import { ProviderInstrumentFormContext } from "./providerInstrumentFormContext";

class AutoGenerateSettings extends Component {
  static contextType = ProviderInstrumentFormContext;

  constructor(props) {
    super();
    this.props = props;
    this.state = {
      config_types: [
        { value: "None", key: 0, label: "None" },
        { value: "Manual", key: 1, label: "Manual" },
        { value: "Range", key: 2, label: "Range" },
        { value: "Random", key: 3, label: "Random" },
      ],
    };
  }

  setAutoGenerateValues = (providerInstrument) => {
      if (providerInstrument.autoGenerateConfig) {
        var config = JSON.parse(providerInstrument.autoGenerateConfig);
        console.debug("Found AutoGenerateConfig: ", config);
        providerInstrument.isTest = true;
        providerInstrument.config_type = config.AutoGenerateType;
        providerInstrument.config_interval = config.IntervalMs;
        providerInstrument.config_range_min = config.RangeMin;
        providerInstrument.config_range_max = config.RangeMax;
        providerInstrument.config_step = config.Step;
        providerInstrument.config_spread = config.Spread;
      }
    return providerInstrument;
  };

  render() {
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    return (
      <div>
        {this.props.shouldRender ? (
          <div>
            <Form.Item
              label="AutoGenerateConfig"
              name="autoGenerateConfig"
              hidden="true"
            ></Form.Item>
            <Form.Item {...tailLayout}>
              {" "}
              <Typography.Paragraph italic type="warning">
                {" "}
                Please note that you are now in test mode. Any provider
                instruments created in this mode will use simulated prices.
              </Typography.Paragraph>{" "}
            </Form.Item>
            <div>
              {" "}
              <Divider orientation="left">Auto Generate Configuration</Divider>
              <Form.Item
                label="Type"
                name="config_type"
                rules={[{ required: true, message: "Type is mandatory!" }]}
              >
                <Select
                  options={this.state.config_types}
                  onChange={this.onConfigTypeChanged}
                ></Select>
              </Form.Item>
              <Form.Item label="Interval" name="config_interval">
                <Input type="number" step="1"></Input>
              </Form.Item>
              <Form.Item label="Minimum value" name="config_range_min">
                <Input type="number" step="0.0001" min="0" />
              </Form.Item>
              <Form.Item label="Maximum value" name="config_range_max">
                <Input type="number" step="0.0001" min="0" />
              </Form.Item>
              <Form.Item label="Step" name="config_step">
                <Input type="number" step="0.0001" min="0" />
              </Form.Item>
              <Form.Item label="Spread" name="config_spread">
                <Input type="number" step="0.0001" min="0" />
              </Form.Item>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

AutoGenerateSettings.contextType = ProviderInstrumentFormContext;
export default AutoGenerateSettings;

export class ProviderInstrumentHelper {

  static emptyTestProviderInstrument() {
    return {
      enabled: true,
      config_type : "Manual",
      config_interval : 500,
      config_range_min : 1.5,
      config_range_max : 2.5,
      config_step : 0.0001,
      config_spread : 0.0005,
      isTest : false,
      used : false
    };
  }

  static extractProviderInstrument(providerInstrument, isTest) {
    if (isTest) {
      return {
        id: providerInstrument.id,
        name: providerInstrument.name,
        providerName : providerInstrument.providerName,
        description: providerInstrument.description,
        bloombergId: providerInstrument.bloombergId,
        isin: providerInstrument.isin,
        enabled: providerInstrument.enabled,
        contractSize : providerInstrument.contractSize,
        currencyCode : providerInstrument.currencyCode,
        minimumStake : providerInstrument.minimumStake,
        stepSize : providerInstrument.stepSize,
        autoGenerateConfig: {
          autoGenerateType: providerInstrument.config_type, 
          intervalMs: providerInstrument.config_interval, 
          rangeMin: providerInstrument.config_range_min, 
          rangeMax: providerInstrument.config_range_max, 
          step: providerInstrument.config_step, 
          spread: providerInstrument.config_spread
        },
      };
    }
    console.log(providerInstrument)
    return {
      id: providerInstrument.id,
      name: providerInstrument.name,
      providerName : providerInstrument.providerName,
      description: providerInstrument.description,
      bloombergId: providerInstrument.bloombergId,
      isin: providerInstrument.isin,
      enabled: providerInstrument.enabled,
      autoGenerateConfig: null,
      contractSize : providerInstrument.contractSize,
      currencyCode : providerInstrument.currencyCode,
      minimumStake : providerInstrument.minimumStake,
      stepSize : providerInstrument.stepSize
    };
  }

  // used by provider instrument
  static mergeProviderInstrument(
    providerInstrument,
    modifiedProviderInstrument
  ) {
    providerInstrument.name = modifiedProviderInstrument.name;
    providerInstrument.enabled = modifiedProviderInstrument.enabled;
    providerInstrument.description = modifiedProviderInstrument.description;
    providerInstrument.bloombergId = modifiedProviderInstrument.bloombergId;
    providerInstrument.isin = modifiedProviderInstrument.isin;
    providerInstrument.contractSize = modifiedProviderInstrument.contractSize;
    providerInstrument.currencyCode = modifiedProviderInstrument.currencyCode;
    providerInstrument.minimumStake = modifiedProviderInstrument.minimumStake;
    providerInstrument.stepSize = modifiedProviderInstrument.stepSize;
  }
}

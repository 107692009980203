import React, { Component } from "react";
import { Layout, Input, Typography, Space, Button, Col, Row } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import * as _ from "underscore";
import "../main.css";
import DynamicDataRepositoryRedisDbProxy from "../proxies/redisDbProxy";
import DynamicDataRepositoryDashProxy from "../proxies/dashProxy";
import ConnectorsCoordinatorProxy from "../proxies/connectorsCoordinatorProxy";
import { Helpers } from "../helpers/helpers";
import { ProviderInstrumentHelper } from "./helpers/providerInstrumentHelper";
import { AppContext } from "../dataContext";
import ProviderInstrumentTable from "./providerInstrumentTable";
import PricingHubProxy from "../proxies/pricinghubProxy";
import DisableWrapper from "../components/disableWrapper";

const { Content } = Layout;
const { Title } = Typography;

class ProviderInstrumentSearch extends Component {
  static contextType = AppContext;

  constructor(props) {
    super();

    this.urlSearch = new URLSearchParams(props.location.search);

    this.throttledSearch = _.throttle(this.search, 1000, {
      loading: false,
    });

    this.dynamicDataRepositoryDashProxy = new DynamicDataRepositoryDashProxy(
      {}
    );
    this.pricingHubProxy = new PricingHubProxy();
    this.ConnectorsCoordinatorProxy = new ConnectorsCoordinatorProxy();
    this.redisDbProxy = new DynamicDataRepositoryRedisDbProxy({
      onProviderInstrument: this.onProviderInstrument,
    });
    this.id = props.params.id;
    this.props = props;
    this.state = {
      loading: false,
      search: undefined,
      id: this.props.params.id,
      providerInstruments: [],
      providers: [],
    };
  }

  onProviderInstrument = (payload) => {
    let providerInstrument = payload.data;
    let foundProviderInstrument = this.state.providerInstruments?.filter(
      (y) => y.id === providerInstrument.id
    )[0];
    if (foundProviderInstrument) {
      foundProviderInstrument.editingDisabled = false;
      ProviderInstrumentHelper.mergeProviderInstrument(
        foundProviderInstrument,
        providerInstrument
      );
      this.throttledSearch();
    }
  };

  search = async () => {
    if (!this._isMounted) return;

    this.oldProviderName = this.providerName;
    this.oldName = this.instrumentName;

    console.log(
      "Searching",
      this.providerName,
      this.instrumentName?.trim(),
      this.page
    );

    let result = await this.pricingHubProxy.providerInstrumentsSearch(
      this.providerName,
      this.state.search,
      this.page
    );

    Helpers.sort(result.providerInstruments, (x) => x.name);

    console.log(result.providerInstruments);
    // all this mapping really should be coming from the back end. I can have different payloads that include the provider name and 'used'
    const usedProviderInstruments =
      await this.pricingHubProxy.identifyUsedProviderInstruments(
        result.providerInstruments.map((x) => x.id)
      );

    const usedProviderIds = new Set(usedProviderInstruments.map((x) => x.id));
    const providerMap = new Map(
      this.state.providers.map((provider) => [provider.id, provider.name])
    );

    result.providerInstruments.forEach((providerInstrument) => {
      providerInstrument.used = usedProviderIds.has(providerInstrument.id);
      providerInstrument.providerName = providerMap.get(
        providerInstrument.providerId
      );
    });

    console.log(result.providerInstruments);

    this.setState({
      providerInstruments: result.providerInstruments,
      total: result.counter,
    });

    this.updateUrl(this.providerName, this.instrumentName);

    if (!result?.cancelled) this.setState({ loading: false });
  };

  onSearchChange = async (event) => {
    console.log(
      "Provider instrument search changed",
      event.target.value,
      this.state.providers
    );
    var search = event.target.value;
    if (search.length > 2) {
      this.providerName = undefined;
      let searchArray = search.split(" ");
      searchArray = searchArray.filter((x) => {
        if (x.includes("#")) {
          this.providerName = x.replace("#", "").toLowerCase();
          return false;
        }
        return true;
      });
      search = searchArray.join(" ");

      let foundProvider = this.state.providers.filter(
        (x) => x.name.toLowerCase() === this.providerName?.trim()
      )[0];

      if (foundProvider) {
        this.setState({ loading: true });
        this.page = 1;
        this.setState({ currentPage: 1 });
        this.throttledSearch();
      } else {
        this.setState({
          providerInstruments: [],
          currentPage: 1,
          total: 0,
          search: search,
        });
        this.updateUrl(null, null);
        this.throttledSearch();
      }
    }
    this.setState({ search: search });
  };

  addNew = () => {
    this.context.showProviderInstrumentAdd();
  };

  loaded = async () => {
    let providers = await this.pricingHubProxy.providers();
    console.log(providers);
    this.setState({ providers: providers });
    this.context.updateMenuSelection("allProviderInstruments");
  };

  componentDidMount = async () => {
    this._isMounted = true;
    await this.redisDbProxy.connect();
    await this.redisDbProxy.subscribeAndOverride();
    let state = this.context.state;

    console.debug(
      "MOUNT...",
      this.props.params.id,
      this.props.params.name,
      state.isLoadedAndReady
    );

    this.loaded();
  };

  componentDidUpdate = (prevProps) => {};

  componentWillUnmount = async () => {
    this._isMounted = false;
    await this.redisDbProxy.stop();
  };

  pageChanged = async (pageData) => {
    this.setState({ currentPage: pageData.current, loading: true });
    this.page = pageData.current;
    this.throttledSearch();
  };

  updateUrl = (providerName, instrument) => {
    providerName = providerName?.trim();
    instrument = instrument?.trim();
    const query = new URLSearchParams();
    console.debug("UpdateUrl: ", providerName, instrument);
    if (providerName && instrument)
      this.props.navigate(
        `/providerInstruments/${providerName}?${query.toString()}`,
        { replace: true }
      );
    else if (providerName && !instrument)
      this.props.navigate(
        `/providerInstruments/${providerName}?${query.toString()}`,
        { replace: true }
      );
    else if (!providerName && instrument)
      this.props.navigate(
        `/providerInstruments/${instrument}?${query.toString()}`,
        { replace: true }
      );
    else
      this.props.navigate(`/providerInstruments?${query.toString()}`, {
        replace: true,
      });
  };

  render() {
    const { search } = this.state;

    return (
      <Layout className="layout">
        <Content style={{ padding: "0 5px" }}>
          <Row justify="space-between">
            <Col span={16}>
              <Space style={{ padding: "0px 0px 15px 0px" }} wrap>
                <Title level={4}>Search</Title>
                <Input
                  allowClear
                  prefix={<SearchOutlined />}
                  placeholder="#provider, external instrument name, description"
                  onChange={this.onSearchChange}
                  style={{ width: 400 }}
                  //value={search}
                />
              </Space>
            </Col>{" "}
            <Col>
              <Space align="end" style={{ padding: "0px 15px 15px 0px" }} wrap>
                <DisableWrapper
                  enabledMessage={`Add a new provider instrument`}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    disabled={!this.context.hasWriteRole}
                    onClick={() => this.addNew()}
                  ></Button>
                </DisableWrapper>
              </Space>
            </Col>
          </Row>
          <ProviderInstrumentTable
            onChange={this.pageChanged}
            loading={this.state.loading}
            currentPage={this.state.currentPage}
            total={this.state.total}
            providerInstruments={this.state.providerInstruments}
            providers={this.state.providers}
          />
        </Content>
      </Layout>
    );
  }
}

ProviderInstrumentSearch.contextType = AppContext;
export default Helpers.withParams(ProviderInstrumentSearch);

import React, { Component } from "react";
import { Layout, Form, Button, Space, Spin, Typography, Divider } from "antd";
import "../../main.css";
import { AppContext } from "../../dataContext";
import PricingHubProxy from "../../proxies/pricinghubProxy";
import { Helpers } from "../../helpers/helpers";

const { Content } = Layout;
const { Text } = Typography;

class ProviderInstrumentResubscribe extends Component {
  static contextType = AppContext;

  constructor(props) {
    super();
    this.pricingHubProxy = new PricingHubProxy();
    console.log(props);
    this.state = {
      loading: true,
      providerInstrumentName: props.providerInstrumentName,
      providerName: props.providerName,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: false });
  };

  handleConfirm = async () => {
    const { providerName, providerInstrumentName } = this.state;
    this.setState({ loading: true });

    try {
      await this.pricingHubProxy.retrySubscription(providerName, providerInstrumentName);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.error("Error while resubscribing:", error);
    }
    this.context.updateContext("isProviderInstrumentResubscribeVisible", false);
  };

  handleCancel = () => {
    this.formRef.current.resetFields();
    this.context.updateContext("isProviderInstrumentResubscribeVisible", false);
  };

  render() {
    const { providerName, providerInstrumentName, loading } = this.state;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <Content style={{ padding: "0 5px" }}>
        <Spin spinning={loading}>
          <Form ref={this.formRef} {...layout} onFinish={this.handleConfirm}>
            <Text strong>
              Are you sure you want to resubscribe to provider instrument
              <strong>
                {" "}
                {providerName} : {providerInstrumentName}{" "}
              </strong>
              ?
            </Text>
            <Divider></Divider>
            <Form.Item
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Space direction="vertical" align="center">
                <Space>
                  <Button key="cancel" onClick={this.handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    key="primary"
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    loading={loading}
                  >
                    Confirm
                  </Button>
                </Space>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </Content>
    );
  }
}

ProviderInstrumentResubscribe.contextType = AppContext;
export default ProviderInstrumentResubscribe;
